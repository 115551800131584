<template>
  <div class="app">
    <consult></consult>
    <navbar :title="lesson.name"></navbar>
    <img :src="lesson.picture_title || img" class="img" alt="" />
    <div class="mini">
      <div class="header">
        <div style="display: flex; align-items: center">
          <img src="../assets/to.png" alt="" /><span
            style="display: inline-block; width: 4.5rem"
          >
            {{ lesson.name || "暂无" }}</span
          >
        </div>
        <div class="r">{{ lesson.explain }}</div>
      </div>
      <div class="item1">
        推荐指数：<van-rate
          v-model="lesson.star"
          :count="lesson.star || 5"
          color="#ffd21e"
          readonly
          size="0.28rem"
        />
      </div>
      <div class="item2">
        {{ lesson.price_explain || "暂无"
        }}<span>￥{{ lesson.price || "暂无" }}</span
        ><span v-if="lesson.max_price"
          >~￥{{ lesson.max_price || "暂无" }}</span
        >
      </div>
      <div class="item1">发证单位：{{ lesson.company || "暂无" }}</div>
      <div class="item1">培训等级：{{ lesson.grade || "暂无" }}</div>
    </div>
    <div class="welfare">
      <div v-for="(item, index) in lesson.welfare" :key="index">
        <img src="../assets/dui.png" alt="" />{{ item }}
      </div>
    </div>
    <div class="subsidy">
      <div class="title">{{ lesson.subsidy_explain || "补贴对象" }}</div>
      <div v-if="lesson.lesson_subsidy.length > 0">
        <div
          class="li"
          v-for="(item, index) in lesson.lesson_subsidy"
          :key="index"
        >
          {{ item.title }}<span>{{ item.subsidy }}</span>
        </div>
      </div>
      <div v-else class="tac">—— 暂无内容 ——</div>
    </div>
    <div class="intro">
      <div class="tbr">
        <div @click="active = 1" :class="active == 1 ? 'active' : ''">
          课程介绍
        </div>
        <div @click="active = 2" :class="active == 2 ? 'active' : 'dian'">
          培训地图
          <span>{{ map.length || 0 }}</span>
        </div>
        <div @click="active = 3" :class="active == 3 ? 'active' : ''">
          培训资料
          <span>{{ information.length || 0 }}</span>
        </div>
      </div>
      <div class="content1" v-show="active == 1">
        <div v-html="lesson.content || '暂无课程介绍'"></div>
        <div style="height: 3rem"></div>
      </div>
      <div class="content2" v-show="active == 2">
        <div class="map1">
          <baidu-map class="map" :center="longitude" :zoom="15">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <bm-marker
              v-for="(item, index) in map"
              :key="index"
              :position="item.map"
              :dragging="false"
              animation="BMAP_ANIMATION_BOUNCE"
            >
              <bm-label
                :content="`${item.area}${item.address}`"
                :labelStyle="{ color: '#000', fontSize: '0.24rem' }"
                :offset="{ width: -80, height: 30 }"
              />
            </bm-marker>
          </baidu-map>
        </div>
        <div
          class="li"
          v-for="(item, index) in map"
          :key="index"
          @click="dw(item)"
        >
          <img src="../assets/dianwei.jpg" alt="" />
          <div class="txt">{{ item.area }}{{ item.address }}</div>
          <div class="km">{{ item.distance }}km</div>
        </div>
        <div style="height: 3rem"></div>
      </div>
      <div class="content3" v-show="active == 3">
        <div
          class="li"
          v-for="(item, index) in information"
          :key="index"
          @click="open(item)"
        >
          <div class="title">{{ item.name }}</div>
          <div class="box">
            <div class="l">
              <img src="../assets/time.png" alt="" />{{ item.time }}
            </div>
            <div class="r">详细 ></div>
          </div>
        </div>

        <div style="height: 3rem"></div>
      </div>
    </div>
    <div class="footer">
      <div class="item" @click="collect(lesson.collection)">
        <img v-if="lesson.collection == 0" src="../assets/sc1.png" alt="" />
        <img
          v-if="lesson.collection == 1"
          style="width: 0.7rem; height: 0.5rem; margin-bottom: 0.1rem"
          src="../assets/sc.png"
          alt=""
        />
        <div>{{ lesson.collection == 0 ? "收藏" : "取消" }}</div>
      </div>
      <div class="item1" @click="share">
        <img src="../assets/fx1.png" alt="" />
        <div>分享</div>
      </div>
      <div class="item2" @click="enroll">在线报名</div>
    </div>
  </div>
</template>

<script>
import map from "../libs/map";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      img: require("../assets/bj.jpg"),
      value: 5,
      active: 1,
      flag: true,
      lng: "",
      lat: "",
      information: {},
      lesson: {},
      map: {},
      longitude: {},
    };
  },
  methods: {
    ...mapActions([
      "getlessonDet",
      "getenroll",
      "getcollection",
      "getcancel_collection",
      "getSignPackage",
      "getforward",
      "getnewsForward",
    ]),
    share() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        this.$toast("点击右上角...进行分享");
      } else {
        //非微信浏览器
        this.$toast("请在手机微信端运行");
      }
    },
    dw(item) {
      this.longitude = item.map;
    },
    open(item) {
      window.open(item.information);
    },
    enroll() {
      if (this.flag == false) {
        this.$dialog
          .confirm({
            title: "",
            message: "登录后可收藏",
          })
          .then(() => {
            this.$router.push("/user");
          })
          .catch(() => {});
        return;
      }
      let that = this;
      this.$dialog
        .confirm({
          title: "是否报名此课程",
          message: "",
        })
        .then(() => {
          that
            .getenroll({
              lesson_id: that.$route.query.id, //课程id
              member_id: that.userinfo.id, //会员id
              wx: that.userinfo.wx, //微信号
              phone: that.userinfo.phone, //手机号
            })
            .then((res) => {
              if (res.SuccessCode == 200) {
                that.$toast.success("报名成功");
              } else {
                that.$toast.fail(res.Message);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },

    collect(val) {
      if (this.flag == false) {
        this.$dialog
          .confirm({
            title: "",
            message: "登录后可收藏",
          })
          .then(() => {
            this.$router.push("/user");
          })
          .catch(() => {});
        return;
      }
      if (val == 0) {
        this.getcollection({
          news_id: this.$route.query.id,
          type: 2,
          member_id: this.userinfo.id,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("收藏成功");
            this.lesson.collection = 1;
          } else {
            this.$toast.fail(res.Message);
          }
        });
      } else {
        this.getcancel_collection({
          news_id: this.$route.query.id,
          type: 2,
          member_id: this.userinfo.id,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("取消成功");
            this.lesson.collection = 0;
          } else {
            this.$toast.fail(res.Message);
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  mounted() {
    var that = this;
    var urls = window.location.href;
    if (this.userinfo) {
      var url = urls + `&invitation=${this.userinfo.invitation}`;
    } else {
      var url = urls + `&invitation=${this.invitationinif.invitation}`;
    }
    map.init().then((BMap) => {
      const locationCity = new BMap.Geolocation();
      locationCity.getCurrentPosition(
        function getinfo(options) {
          console.log(options, 33333);
          let city = options.address.city;
          that.LocationCity = city;
          that.lng = options.point.lng;
          that.lat = options.point.lat;
          if (that.userinfo == "") {
            that.flag = false;
            that
              .getlessonDet({
                id: that.$route.query.id,
                lng: options.point.lng,
                lat: options.point.lat,
                member_id: 262,
              })
              .then((res) => {
                if (res.SuccessCode == 200) {
                  that.information = res.ResponseBody.information;
                  res.ResponseBody.lesson.welfare =
                    res.ResponseBody.lesson.welfare.split(",");
                  if (res.ResponseBody.map.length > 0) {
                    that.longitude = {
                      lng: res.ResponseBody.map[0].longitude,
                      lat: res.ResponseBody.map[0].latitude,
                    };
                    res.ResponseBody.map.forEach((e) => {
                      e.distance = e.distance.toFixed(2);
                      e.map = {
                        lng: e.longitude,
                        lat: e.latitude,
                      };
                    });
                  }
                  that.lesson = res.ResponseBody.lesson;
                  that.map = res.ResponseBody.map;
                  that.$store.commit("SET_shareinformation", {
                    title: that.lesson.name,
                    imgurl: that.lesson.picture_title,
                    type: 2,
                  });
                  that
                    .getSignPackage({
                      url: urls,
                    })
                    .then((res) => {
                      wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
                        timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
                        signature: res.ResponseBody.signature, // 必填，签名
                        jsApiList: [
                          "onMenuShareTimeline",
                          "onMenuShareAppMessage",
                          "updateAppMessageShareData",
                          "updateTimelineShareData",
                        ], // 必填，需要使用的JS接口列表
                      });
                      wx.ready(function (res) {
                        wx.onMenuShareTimeline({
                          title: that.lesson.name, // 分享标题
                          desc: "沪惠学", // 分享描述
                          link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                          imgUrl: that.lesson.picture_title, // 分享图标
                          success: function () {
                            //记录分享次数
                            if (that.userinfo) {
                              that.getforward({
                                member_id: that.userinfo.id,
                                invitation: that.userinfo.invitation,
                                type: "2",
                              });
                            }

                            that.getnewsForward({
                              id: that.lesson.id,
                            });
                          },
                        });

                        wx.onMenuShareAppMessage({
                          title: that.lesson.name, // 分享标题
                          desc: "沪惠学", // 分享描述
                          link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                          imgUrl: that.lesson.picture_title, // 分享图标
                          success: function () {
                            //记录分享次数
                            if (that.userinfo) {
                              that.getforward({
                                member_id: that.userinfo.id,
                                invitation: that.userinfo.invitation,
                                type: "2",
                              });
                            }

                            that.getnewsForward({
                              id: that.lesson.id,
                            });
                          },
                        });
                      });
                      wx.error(function (eer) {
                        console.log(eer, 3333);
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                      });
                    });
                }
              });
          } else {
            that
              .getlessonDet({
                id: that.$route.query.id,
                lng: options.point.lng,
                lat: options.point.lat,
                member_id: that.userinfo.id,
              })
              .then((res) => {
                if (res.SuccessCode == 200) {
                  that.information = res.ResponseBody.information;
                  res.ResponseBody.lesson.welfare =
                    res.ResponseBody.lesson.welfare.split(",");
                  if (res.ResponseBody.map.length > 0) {
                    that.longitude = {
                      lng: res.ResponseBody.map[0].longitude,
                      lat: res.ResponseBody.map[0].latitude,
                    };
                    res.ResponseBody.map.forEach((e) => {
                      e.distance = e.distance.toFixed(2);
                      e.map = {
                        lng: e.longitude,
                        lat: e.latitude,
                      };
                    });
                  }
                  that.lesson = res.ResponseBody.lesson;
                  that.map = res.ResponseBody.map;
                  that.$store.commit("SET_shareinformation", {
                    title: that.lesson.name,
                    imgurl: that.lesson.picture_title,
                    type: 2,
                  });
                  that
                    .getSignPackage({
                      url: urls,
                    })
                    .then((res) => {
                      wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
                        timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
                        signature: res.ResponseBody.signature, // 必填，签名
                        jsApiList: [
                          "onMenuShareTimeline",
                          "onMenuShareAppMessage",
                          "updateAppMessageShareData",
                          "updateTimelineShareData",
                        ], // 必填，需要使用的JS接口列表
                      });
                      wx.ready(function (res) {
                        wx.onMenuShareTimeline({
                          title: that.lesson.name, // 分享标题
                          desc: "沪惠学", // 分享描述
                          link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                          imgUrl: that.lesson.picture_title, // 分享图标
                          success: function () {
                            //记录分享次数
                            if (that.userinfo) {
                              that.getforward({
                                member_id: that.userinfo.id,
                                invitation: that.userinfo.invitation,
                                type: "2",
                              });
                            }

                            that.getnewsForward({
                              id: that.lesson.id,
                            });
                          },
                        });

                        wx.onMenuShareAppMessage({
                          title: that.lesson.name, // 分享标题
                          desc: "沪惠学", // 分享描述
                          link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                          imgUrl: that.lesson.picture_title, // 分享图标
                          success: function () {
                            //记录分享次数
                            if (that.userinfo) {
                              that.getforward({
                                member_id: that.userinfo.id,
                                invitation: that.userinfo.invitation,
                                type: "2",
                              });
                            }

                            that.getnewsForward({
                              id: that.lesson.id,
                            });
                          },
                        });
                      });
                      wx.error(function (eer) {
                        console.log(eer, 3333);
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                      });
                    });
                }
              });
          }
        },
        function (e) {
          that.LocationCity = "定位失败";
          that.$toast.success("定位失败");
        },
        { provider: "baidu" }
      );
    });
  },
};
</script>

<style lang='scss' scoped>
.app {
  width: 7.5rem;
  overflow: hidden;
}
.img {
  height: 3.5rem;
  width: 100%;
}
.mini {
  position: relative;
  z-index: 100;
  margin-top: -0.4rem;
  padding: 0.18rem 0;
  margin-left: 0.24rem;
  width: 7.02rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 0.45rem;
      vertical-align: middle;
    }
    span {
      margin-left: 0.19rem;
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .r {
      margin-right: 0.2rem;
      padding: 0.02rem 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      background: #f9a753;
      border-radius: 17px;
    }
  }
  .item1 {
    padding: 0 0.2rem;
    margin: 0.15rem 0;
    box-sizing: border-box;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7c7c7c;
  }
  .item2 {
    padding: 0 0.2rem;
    margin: 0.15rem 0;
    margin-top: 0;
    box-sizing: border-box;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7c7c7c;
    span {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e02020;
    }
  }
}
.welfare {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
  padding: 0.32rem 0.24rem 0.12rem;
  box-sizing: border-box;
  margin-left: 0.24rem;
  width: 7.02rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  div {
    margin-right: 0.48rem;
    margin-bottom: 0.2rem;
    display: flex;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7c7c7c;
  }
  img {
    margin-right: 0.13rem;
    width: 0.37rem;
    height: 0.37rem;
  }
}
.subsidy {
  margin-top: 0.2rem;
  padding: 0.29rem 0.24rem;
  box-sizing: border-box;
  margin-left: 0.24rem;
  width: 7.02rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  .title {
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    font-size: 0.26rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    &::before {
      margin-right: 0.14rem;
      content: "";
      display: inline-block;
      width: 0.07rem;
      height: 0.28rem;
      background: #458aec;
      border-radius: 0.04rem;
    }
  }
  .li {
    margin-bottom: 0.2rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
    display: flex;
    align-items: center;
    &::before {
      margin-right: 0.14rem;
      content: "";
      display: inline-block;
      width: 0.11rem;
      height: 0.11rem;
      background: #2978ee;
      border-radius: 0.06rem;
    }
    span {
      margin-left: 0.2rem;
      padding: 0 0.5rem;
      box-sizing: border-box;
      display: inline-block;
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff5a00;
      background: #ffe8d0;
      border-radius: 0.17rem;
      border: 0.01rem solid #f7b500;
    }
  }
}
.intro {
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  margin-left: 0.24rem;
  width: 7.02rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  .tbr {
    display: flex;
    justify-content: space-around;
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
    div {
      position: relative;
      padding: 0.3rem 0;
      span {
        padding: 0 0.12rem;
        font-size: 0.22rem;
        position: absolute;
        top: 0.15rem;
        right: -0.2rem;

        display: inline-block;
        background-color: red;
        color: #ffffff;
        border-radius: 1rem;
      }
    }

    .active {
      color: #2978ee;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 0.46rem;
        height: 0.08rem;
        background: #2978ee;
      }
    }
  }
  .content1 {
    font-size: 0.28rem;
  }
  .content2 {
    .map1 {
      margin-top: 0.1rem;
      margin-left: 0.29rem;
      width: 6.54rem;
      height: 4.5rem;
    }
    .map {
      width: 6.54rem;
      height: 4.5rem;
    }
    .li {
      box-sizing: border-box;
      margin-top: 0.2rem;
      display: flex;
      margin-left: 0.24rem;
      width: 6.56rem;
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.2rem;
      img {
        margin-right: 0.2rem;
        width: 0.4rem;
        height: 0.4rem;
      }
      .txt {
        width: 4.5rem;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141f33;
      }
      .km {
        margin-left: 0.5rem;
        float: right;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141f33;
      }
    }
  }
  .content3 {
    .li {
      width: 6.28rem;
      margin-left: 0.5rem;
      margin-bottom: 0.2rem;
      .title {
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141f33;
      }
      .box {
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l {
          img {
            width: 0.3rem;
            vertical-align: top;
            margin-right: 0.1rem;
          }
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .r {
          font-size: 0.26rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;
        }
      }
    }
  }
}
.footer {
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  width: 7.5rem;
  height: 1.64rem;
  background: #ffffff;
  box-shadow: 0rem -0.04rem 0.08rem 0rem #f5f7fa;
  .item {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #141f33;
    text-align: center;

    margin: 0.42rem 0 0 0.39rem;
    img {
      width: 0.6rem;
    }
  }
  .item1 {
    margin: 0.42rem 0 0 0.61rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #141f33;
    text-align: center;
    img {
      width: 0.6rem;
    }
  }
  .item2 {
    margin-left: 0.8rem;
    margin-top: 0.31rem;
    width: 4.09rem;
    height: 0.76rem;
    background: #2978ee;
    border-radius: 0.1rem;
    font-size: 0.28rem;
    text-align: center;
    line-height: 0.76rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>